<template>
  <div>
    <b-card title="Ongoing Job Order">
      <b-col cols="8" v-if="permission.add">
        <b-button
          style="margin-bottom: 15px"
          variant="primary"
          :to="{ name: 'add-job-order' }"
        >
          Create Job Order
        </b-button>
      </b-col>
      <b-row align-h="end" style="margin-bottom:15px;">
        <b-col cols="4">
          <b-form-input
            v-model="search"
            @input="debounceSearch"
            type="search"
            placeholder="Type to Search"
            style="float: right;"
          />
        </b-col>
      </b-row>
      <b-row style="margin-bottom:10px" class="show-on-mobile">
        <b-col>
          <b-form-checkbox
            v-model="stackedStatus"
            value="md"
            unchecked-value="false"
          >
            Stacked Table
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :filter="search"
                  hover
                  responsive
                  small
                  head-variant="dark"
                  outlined
                  :items="jobOrders"
                  :fields="headers"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :stacked="stackedStatus"
                  show-empty
                >
                  <template #cell(orderNumber)="row">
                    {{ row.item.orderNumber }}
                  </template>
                  <template #cell(type)="row">
                    {{ formatType(row.item.props.type) }}
                  </template>
                  <template #cell(props.status)="row">
                    {{ formatStatus(row.item.props.status) }}
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon"/>
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="success"
                          size="sm"
                          :to="{name:'job-order-view', params:{id:row.item.id}}"
                          
                          style="margin-right:10px"
                          ><feather-icon size="1x" icon="EyeIcon"
                        /></b-button>
                      </b-dropdown-item>
                      <!-- <b-dropdown-item v-if="permission.edit && !needDisableApproveButton(row.item) && row.item.status != 'Canceled'">
                        <b-button
                          variant="success"
                          size="sm"
                          style="margin-right:10px"
                          @click="
                            approveDetail(row.item);
                            promptDetail = true;
                            dataApproval = row.item;
                          "
                          >Approve</b-button
                        >
                      </b-dropdown-item> -->
                      <!-- <b-dropdown-item v-if="permission.edit && !needDisableRejectButton(row.item)">
                        <b-button
                          variant="danger"
                          size="sm"
                          style="margin-right:10px"
                          @click="reject(row.item)"
                          >Reject</b-button
                        >
                      </b-dropdown-item> -->
                      <b-dropdown-item v-if="row.item.props.type !== 'Recoil' &&  !enableCancel(row.item) && permission.edit ">
                        <b-button
                          variant="primary"
                          size="sm"
                          :to="{ name: 'update-pick-list-order', params: { id: row.item.id } }"
                          style="margin-right: 10px;">
                          Update Picklist
                        </b-button>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="row.item.props.type !== 'Recoil' && !enableCancel(row.item) && permission.delete">
                        <b-button
                          variant="warning"
                          size="sm"
                          @click="cancelJob(row.item)"
                          style="margin-right: 10px;">
                          Cancel Job
                        </b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryJobOrder()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                @change="onPageChange"
                :total-rows="totalRows"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"
              ></b-pagination>
            </b-col>
          </b-row>
      </b-col>
  </b-card>

    <!-- Modal for showing Job Order Details When Clicking Approve-->
    <b-modal
        v-model="promptDetail"
        title="Approve Job Order"
        id="promptDetail"
        size="lg"
        ok-only
      >
        <b-row>
          <b-col cols="3">
            Job Order Number :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.orderNumber }}
          </b-col>
          <b-col cols="3">
            Type :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.type }}
          </b-col>
          <b-col cols="3">
            Created At:
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.createdAt }}
          </b-col>
          <b-col cols="3">
            Updated At :
          </b-col>
          <b-col cols="9">
            {{ detailModal.content.updatedAt }}
          </b-col>
          <b-col cols="12" style="margin-top:10px">
            <b-table
              :items="detailModal.content.items"
              :fields="items_detail_fields"
              responsive="sm"
              hover
              head-variant="dark"
              outlined
              small
              stacked="md"
            >
              <template #cell(no)="row">
                {{ row.index + 1 }}
              </template>
            </b-table>
            <div>
              <b-button
                variant="warning"
                size="sm"
                block
                @click="
                  approve(dataApproval);
                  promptDetail = false;
                "
                >Approve</b-button
              >
            </div>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100"></div>
        </template>
    </b-modal>
    <!-- Modal for confirmation canceled Job Order -->
    <b-modal
    v-model="modalConfirmCancelJob"
    title="Cancel Job Order"
    hide-header-close
    size="md"
    id="modal-scrollable">
    <span style="margin-bottom: 10px;"><b>Do you cancel Job Order?</b></span>
    <template #modal-footer>
      <b-button variant="success" :disabled="disableButton" @click="confirmCancelJob">
        Ok
      </b-button>
      <b-button variant="danger" :disabled="disableButton" @click="modalConfirmCancelJob = false">
        Cancel
      </b-button>
    </template>
    </b-modal>
    <!-- Modal for Can Not Cancel Job Order -->
    <b-modal
    v-model="modalDisabledCancelJob"
    hide-header-close
    centered
    header-bg-variant="danger"
    size="md"
    id="modal-scrollable"
    ok-only>
    <span style="margin-bottom: 10px;"><b>You Have picklist status Number 35 or heigher in Job Order</b></span>
    <template #modal-header>
      <h4 style="color: whitesmoke;">Can Not Cancel Job Order !!!</h4>
    </template>
    <template #modal-footer>
      <b-button variant="success" @click="modalDisabledCancelJob = false">
        Ok
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { userAccess } from "@/utils/utils.js";
export default {
  data() {
    return {
      stackedStatus: "md",
      showSpinner: false,
      headers: [
        {
          key: "orderNumber",
          label: "Job Order Number",
          filterable: true,
          sortable: false,
        },
        // { key: 'orderNumber', label: 'Order Number', filterable: true, sortable: true },
        { key: "drumID", label: "From Batch", filterable: true, sortable: false },
        { key: "type", label: "Type", filterable: true, sortable: false },
        { key: "props.status", label: "Status", formatter: 'jobStatus'},
        { key: "actions", label: "Actions" },
      ],
      //sort direction list
      directions: [
        { key: false, label: "Asc", sortable: false },
        { key: true, label: "Desc", sortable: false },
      ],
      items_detail_fields: ["name", "orderNumber", "quantity", "action"],
      workorderNumModal: false,
      workorderNumber: null,
      jobContext: null,
      search: null,
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      promptOption: false,
      dataWarehouse: "",
      promptDetail: false,
      dataApproval: "",
      modalConfirmCancelJob : false,
      modalDisabledCancelJob : false,
      disableButton: false,

      //for show jobdetails
      showJobModal: false,
      detailModal: {
        id: "detail-modal-bom",
        title: "",
        content: {
          // jobOrderNumber:'',
          name: "",
          type: "",
          createdAt: "",
          updatedAt: "",
        },
      },
    };
  },
  

  methods: {
    ...mapActions({
        getJobOrders: "joborder/getOngoingJobOrder",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryJobOrder(){
        this.getJobOrders({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },

      onPageChange(page) {
        this.currentPage = page;
        this.queryJobOrder();
      },

    formatStatus(value){
      switch(value) {
        case 10:
          return 'Created';
        case 15:
          return 'Can Pick Drum';
        case 20:
          return 'Job Type Confirm';
        case 25:
          return 'Picking Drum Ended';
        case 30:
          return 'Machine Selected';
        case 35:
          return 'Direct Sales Accept';
      }
    },

    formatType(value){
      switch(value) {
        case "Direct Sale":
          return "Direct Sale - 直销"
        case "Cutting":
          return "Cutting - 剪线"
        case "Recoil":
          return "Recoil - 卷线换轴"
        case "Direct Short Length":
          return "Direct Short Length - 剪短线"
      }
    },

    confirmCancelJob() {
      this.disableButton = true
      this.$store.dispatch("joborder/cancelJobOrder", { id: this.dataJob.id }).then((res) => {
        this.queryJobOrder()
        this.disableButton = false
        this.modalConfirmCancelJob = false
        this.$bvToast.toast(res, {
            title: "Success",
            variant: "success",
            solid: true,
          });
      }).catch((err) => {
        this.disableButton = false
        this.modalConfirmCancelJob = false
        this.$bvToast.toast("Cancel Job failed, PickList already processed", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
      })
    },
    // add spinner while loading on searching data process
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },

    enableCancel(jobOrder) {
      // If Created & Machine Selected, Enable Cancel Job & Update PickList Button
      if (jobOrder.props.status == 15 || jobOrder.props.status == 20 || jobOrder.props.status == 25 || jobOrder.props.status == 60) {
        return true;
      }
      return false;
    },

    selectedItem(id) {
      if (id) {
        return this.items.filter((el) => {
          return el.id === id;
        })[0];
      }
    },
    //deprecated functions
    needDisableApproveButton(jobOrder) {
      // Job Order Already Approved Disable Approved Button
      if (jobOrder.status === "Created" || jobOrder.status === "Approved" || jobOrder.status === "Fetching" || jobOrder.status === "Machine Selected" ) {
        return true;
      }
      if (jobOrder.status === "Rejected") {
        return true;
      }
      return false;
    },
    cancelJob(dataJob) {
      this.dataJob = dataJob;
      if (dataJob.disabledCancelJob == true) {
        this.modalDisabledCancelJob = true;
      } else {
        this.modalConfirmCancelJob = true;
      }
    },
    approveDetail(item, index, event) {
      this.$store
        .dispatch("joborder/getJobOrderId", { id: item.id })
        .then((res) => {
          var d = this.$store.getters["joborder/getJobOrderId"];
          this.detailModal.title = "Detail Job Order";
          (this.detailModal.id = d.id),
            (this.detailModal.content = {
              orderNumber: d.orderNumber,
              type: d.props.type,
              items: d.item.map((x) => {
                return {
                  name: x.item.name,
                  orderNumber: x.orderNumber,
                  quantity: x.quantity,
                };
              }),
              createdAt: d.createdAt,
              updatedAt: d.updatedAt,
            });
        });
    },
    approve(data) { 
      // item to be objectID

      let itemOnlyId = data.item.map((el) => {
        return {
          orderNumber: el.orderNumber,
          quantity: el.quantity,
          pickList: el.pickList.id,
          //item: el.item.id
        }
      })
      
      let sendStatus = {
        ...data,
        drumID: data.drumID,
        status: "Approved",
      };

      sendStatus.item = itemOnlyId;
      this.$store
        .dispatch("joborder/updateJobOrder", {
          id: sendStatus.id,
          data: sendStatus,
        })
        .then(() => {
          this.$bvToast.toast("Successfully change status to 'Approved'", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
      // Add new fetch data
      let newFetchData = {
        JobOrderID: data.id, // Job Order ID
        drumID:data.drumID,
        item: itemOnlyId, // Item data
        props: data.props, // Props data
      };
      this.$store
        .dispatch("fetch/addFetch", newFetchData)
        .then(() => {
          this.$bvToast.toast("Successfully added new Fetch data", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Failed to add new Fetch data", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
      this.$store
        .dispatch("joborder/getJobOrder")
        .catch((err) => console.log(err));
    },
  },
  computed: {
    jobOrders() {
      // filter jo dengan status yang bukan finish (ongoing joborder)
      return this.$store.getters["joborder/getOngoingJobOrder"]
    },
    permission() {
      return userAccess("ongoing job order");
    },
    rows() {
      return this.jobOrders.length;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.headers
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageOngoingJoborderManufacture", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByOngoingJoborderManufacture", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescOngoingJoborderManufacture", sortDescNew);
    },
  },

  mounted() {
    // this.$store.dispatch("picklist/getPicklist");
    // this.$store.dispatch("joborder/getJobOrder");
    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageOngoingJoborderManufacture")) {
      this.perPage = this.$session.get("perPageOngoingJoborderManufacture");
    }
    if (this.$session.has("sortByOngoingJoborderManufacture")) {
      this.sortBy = this.$session.get("sortByOngoingJoborderManufacture");
    }
    if (this.$session.has("sortDescOngoingJoborderManufacture")) {
      this.sortDesc = this.$session.get("sortDescOngoingJoborderManufacture");
    }
    
    this.queryJobOrder();
  },
};
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
